@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;

app-mandatory-graduation-survey-modal {
  & .modal-footer {
    & fudis-button:first-child > button {
      margin-right: spacing.$spacing-xs;
    }
  }

  & app-graduation-instructions-url > p > span {
    font-size: typography.$body-text-lg-font-size;
  }
}
