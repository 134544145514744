@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;
@use '../../mixins' as top-navigation;

sis-login-dropdown {
  & .login-dropdown {
    display: inline-block;

    &__toggle {
      display: inline-block;
      background-color: transparent;
      padding-right: spacing.$spacing-xs;
      padding-left: spacing.$spacing-sm;
      line-height: 5.6rem;
      color: colors.$white;
      font-weight: typography.$font-weight-regular;

      &:hover {
        background-color: colors.$middle-gray;
      }

      &:focus {
        @include focus.fudis-focus-generic;
      }
    }

    &__menu {
      @include top-navigation.settings-menu-group;

      &__item {
        min-width: max-content;
        color: colors.$primary-color-dark;
        font-size: typography.$body-text-md-font-size;

        &:active,
        &:hover,
        &:focus {
          color: colors.$primary-color-dark !important;
        }
      }
    }
  }
}
