@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;

sis-sort-by-indicator {
  & sis-icon[icon="sorter"] svg {
    cursor: pointer;
  }

  @media (max-width: breakpoints.$screen-lg-max) {
    & sis-icon {
      display: block;
    }
  }
}
