@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

plan-messages {
  & .to-other-plans {
    display: inline-block;
    margin-top: 2rem;
    font-weight: 400;
    font-style: italic;
  }

  & h2.plan-name {
    margin-top: 0;
    margin-bottom: 0;
  }

  & .plan-description {
    line-height: 1.8rem;
    color: colors.$middle-gray;
    font-size: 1.2rem;
    font-weight: 300;
  }

  & #other-plans {
    position: relative;
    top: -10.5rem;
    width: 0;
    height: 0;
  }

  & .other-plans-divider {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 2rem;
    text-align: center;

    & .divider {
      border-bottom: spacing.$pixel-1 solid colors.$silver;
      width: 100%;
    }

    & p.guidance {
      margin: 0 1.5rem;
      min-width: 20rem;
      line-height: 2rem;

      @media (max-width: breakpoints.$screen-sm-max) {
        line-height: 1.8rem;
      }
    }
  }

  & .plan-container {
    margin-bottom: 1.5rem;
    min-height: 4.5rem;

    & .accordion-panel-noblock {
      & .header {
        & button.collapse.non-button {
          overflow: hidden;
        }
      }

      & .content {
        margin-bottom: 0;

        & .content-text {
          padding-bottom: 0;

          & ul.messenger {
            margin-bottom: 0;

            & > li.messenger-new {
              margin: 0;
              min-height: 0;
            }
          }
        }
      }
    }

    & hr {
      border-top-color: colors.$extra-light-gray;
    }
  }
}
