@forward './conversations';
@forward './sis-message-conversation-list-item.component';
@forward './sis-frontpage-message-conversations.component';
@forward './messages';
@forward './planMessages';
@forward './sis-message.component';
@forward './sis-message-conversation.component';
@forward './suggestAddMessage';
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

ul.messenger {
  $messenger-study-left-bg: colors.$extra-light-gray;
  $messenger-study-right-bg: colors.$light-gray;
  $messenger-note-left-bg: colors.$white;
  $messenger-note-left-border: colors.$light-gray;
  $messenger-note-right-bg: colors.$white;
  $messenger-note-right-border: colors.$light-gray;
  $messenger-ball-border: colors.$silver;
  $messenger-first-ball-fill: colors.$light-gray;
  $messenger-line-color: colors.$silver;

  margin-top: 1rem;
  margin-bottom: 0;
  background: colors.$white;
  padding-left: 0;
  width: 100%;
  list-style: none;
  font-weight: 400;

  &.messenger-in-one-column {
    margin-top: 0;

    & li.messenger-right {
      margin-left: 0;
      padding-bottom: 1.5rem;
      width: 100%;
    }

    & .messenger-cur-message {
      & .messenger-inner {
        & .messenger-title {
          margin-bottom: spacing.$pixel-3;
          font-weight: 600;

          & .title-not-in-this-language {
            margin-top: -0.5rem;
            margin-bottom: spacing.$spacing-xxs;
            font-weight: 400;
            font-style: italic;
          }

          & .messenger-timestamp {
            text-align: right;
            font-weight: 400;
          }
        }

        & .messenger-author {
          text-align: right;
        }

        & .messenger-recipients-list {
          margin-top: spacing.$pixel-3;
          line-height: spacing.$spacing-sm;
          color: colors.$middle-gray;
        }
      }
    }

    & .messenger-line-ends {
      position: relative;
      top: 2rem;
      left: -0.4rem;
      margin: 0;
      border-bottom: spacing.$pixel-3 solid colors.$silver;
      padding: 0;
      width: 0.9rem;
      min-height: spacing.$pixel-1;
    }
  }

  // general li stuff
  & > li {
    position: relative;
    margin: -2rem 0;
    padding: 0.5rem 0 1.5rem;
    width: 50%;
    min-height: spacing.$spacing-xl;

    &:first-child {
      margin-top: 0;

      // if no 'new message' box is visible, block the line from growing upwards from the uppermost ball
      &:not(.messenger-new) {
        &::after {
          display: inline-block;
          position: absolute;
          top: -0.2rem;
          left: -1.1rem;
          background: colors.$white;
          width: 2rem;
          height: 3.3rem;
          content: "";
        }

        // make the first ball different colour
        &.messenger-study, &.messenger-note {
          &::before {
            background: $messenger-first-ball-fill;
          }
        }
      }

      &.messenger-history-begin {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      // first li is the 'new message' box, make the ball different colour
      &.messenger-new {
        & + .messenger-study, & + .messenger-note {
          &::before {
            background: $messenger-first-ball-fill;
          }
        }
      }
    }

    // 2nd to last li (last message or 'new message' box if history empty) gets a longer line
    &:nth-last-child(2) {
      padding-bottom: 2.5rem;
    }

    // general ball stuff
    &.messenger-study, &.messenger-note {
      &::before {
        display: inline-block;
        position: absolute;
        top: 3.1rem;
        border: spacing.$pixel-1 solid $messenger-ball-border;
        border-radius: 50%;
        background: colors.$white;
        width: 0.9rem;
        height: 0.9rem;
        content: "";
      }

      & .courseunit, .studymodule {
        margin-bottom: spacing.$pixel-3;
        border: spacing.$pixel-3 solid colors.$white;
      }
    }

    // prepare the inner message box
    & .messenger-inner {
      position: relative;
      z-index: 9; // li marginaalin takia boxit menee limittäin. Tällä z-indexillä nostetaan messenger-inner laatikkoja niin etteivät ne mene limittäin.
      border-radius: spacing.$pixel-3;
      padding: 1.2rem;
      min-height: 6rem;
      line-height: 1.4rem;
      font-size: 1.2rem;

      // prepare the arrow
      &::before {
        position: absolute;
        top: 2.2rem;
        border-width: 0.7rem;
        border-style: solid;
        border-color: transparent;
        width: 0;
        height: 0;
        content: " ";
      }

      & ul.messenger-language-selector {
        position: absolute;
        top: -0.1rem;
        right: -2.2rem;
        padding-left: 0;

        & li {
          position: relative;
          border: spacing.$pixel-1 solid colors.$light-gray;
          border-top-width: 0;
          background: colors.$extra-light-gray;
          width: 2.2rem;
          height: spacing.$spacing-md;
          list-style: none;
          text-align: center;

          &:first-child {
            border-top-width: spacing.$pixel-1;
            border-top-right-radius: spacing.$pixel-3;
            height: 2.5rem;

            &::before {
              display: inline-block;
              position: absolute;
              top: -0.1rem;
              left: -0.2rem;
              border-top: spacing.$pixel-1 solid colors.$light-gray;
              border-right: spacing.$pixel-1 solid colors.$light-gray;
              background: colors.$white;
              width: spacing.$pixel-2;
              height: spacing.$pixel-2;
              content: "";
            }

            &.active {
              &::before {
                border-right-width: 0;
              }
            }
          }

          &:last-child {
            border-bottom-right-radius: spacing.$pixel-3;
          }

          &.active {
            border-left-width: 0;
            background: colors.$white;
          }

          & > a {
            display: inline-block;
            width: 2rem;
            height: spacing.$spacing-md;
            line-height: 2.2rem;
            color: colors.$dark-gray;
            font-size: 1.2rem;

            &:hover, &:active, &:visited {
              text-decoration: none;
            }
          }
        }
      }

      &.deleted {
        & .message-text {
          font-style: italic;

          & sis-icon[icon="delete"] {
            vertical-align: 10%;
          }
        }
      }

      // title row
      & .message-title-row {
        & .message-title {
          font-weight: 600;
        }

        & .message-info {
          text-align: right;

          & .messenger-timestamp {
            display: block;
            margin-bottom: spacing.$pixel-3;
          }
        }
      }

      & .addition-box {
        margin-top: 0.5rem;

        & custom-study-draft {
          & .courseunit {
            margin-top: 0;
          }
        }
      }

      & .message-parent-module-info {
        margin-top: 1.3rem;

        & em {
          font-weight: 600;
        }
      }

      & .messenger-message {
        margin-top: 1.3rem;

        & .message-comment {
          white-space: pre-line;
          overflow-wrap: break-word;

          & ul {
            display: inline-block;
            margin-top: spacing.$pixel-3;
            padding-left: 3.5rem;
            width: 100%;
          }

          & .message-not-in-this-language {
            color: colors.$middle-gray;
            font-style: italic;
          }
        }
      }

      // general column things for maximising usable space
      @media (min-width: breakpoints.$screen-md-min) {
        & [class^='col-'] {
          padding: 0 0.5rem;

          &:first-child {
            padding-left: 2rem;
          }

          &:last-child {
            padding-right: 2rem;
          }
        }
      }

      @media (max-width: breakpoints.$screen-md-min) {
        .courseunit, .studymodule {
          margin-bottom: 0.5em;
        }
      }

    } // & .messenger-inner

    // left side message boxes
    &.messenger-left {
      padding-right: 1.5rem;

      // two consecutive messages on the left side, make it so they don't overlap each other
      &:not(.messenger-new) {
        & + .messenger-left {
          &::before {
            top: 5rem;
          }

          & .messenger-inner {
            margin-top: 2rem;
          }
        }
      }

      // make the line
      &.messenger-note, &.messenger-study {
        border-right: spacing.$pixel-1 solid $messenger-line-color;

        &::before {
          right: -0.5rem;
          z-index: 9;
          background: colors.$white;
        }

        & .messenger-inner {
          &::before { // triangle border AND triangle color for studies
            right: -1.4rem;
            border-left-color: $messenger-note-left-border; //triangle border
          }

          // extra arrow to make it follow the border
          &::after { // triangle bg
            position: absolute;
            top: 2.2rem;
            right: -1.3rem;
            border-width: 0.7rem;
            border-style: solid;
            border-color: transparent;
            border-left-color: $messenger-note-left-bg; //triangle bg
            width: 0;
            height: 0;
            content: " ";
          }
        }
      }

      // left side study suggestion message colours
      &.messenger-study {
        & .messenger-inner {
          border: spacing.$pixel-1 solid $messenger-study-left-bg;
          background: $messenger-study-left-bg;

          &::before {
            border-left-color: $messenger-study-left-bg;
          }

          &::after {
            display: none; // hide triangle border
          }
        }
      }

      // left side freeform message colours
      &.messenger-note {
        & .messenger-inner {
          border: spacing.$pixel-1 solid $messenger-note-left-border;
          background: $messenger-note-left-bg;
        }
      }
    } // &.messenger-left

    // right side message boxes
    &.messenger-right {
      margin-left: calc(50% - 0.1rem);
      padding-left: 1.5rem;

      // two consecutive messages on the right side, make it so they don't overlap eachother
      &:not(.messenger-new) {
        & + .messenger-right {
          &::before {
            top: 5rem;
          }

          & .messenger-inner {
            margin-top: 2rem;
          }
        }
      }

      &.messenger-note, &.messenger-study {
        border-left: spacing.$pixel-1 solid $messenger-line-color;

        &::before {
          left: -0.5rem;
        }

        & .messenger-inner {
          &::before {
            left: -1.4rem;
            border-right-color: $messenger-note-right-border;
          }

          // extra arrow to make it follow the border
          &::after {
            position: absolute;
            top: 2.2rem;
            left: -1.3rem;
            border-width: 0.7rem;
            border-style: solid;
            border-color: transparent;
            border-right-color: $messenger-note-right-bg;
            width: 0;
            height: 0;
            content: " ";
          }
        }
      }

      // right side study suggestion message colours
      &.messenger-study {
        & .messenger-inner {
          border: spacing.$pixel-1 solid $messenger-study-right-bg;
          background: $messenger-study-right-bg;

          &::before {
            border-right-color: $messenger-study-right-bg;
          }

          &::after {
            display: none;
          }
        }
      }

      // right side freeform message colours
      &.messenger-note {
        & .messenger-inner {
          border: spacing.$pixel-1 solid $messenger-note-right-border;
          background: $messenger-note-right-bg;
        }
      }
    } //  &.messenger-right

    // 'new message' box rules
    &.messenger-new {
      position: relative;
      margin-bottom: 2rem;

      &.messenger-left {
        & .messenger-inner {
          margin-right: spacing.$pixel-1;
        }
      }

      &.messenger-right {
        & .messenger-inner {
          margin-left: spacing.$pixel-1;
        }
      }

      & [class^='col-'] {
        padding: 0 0.5rem;

        &:first-child {
          padding-left: 2rem;
        }

        &:last-child {
          padding-right: 2rem;
        }
      }

      & .messenger-inner {
        border: spacing.$pixel-1 dashed colors.$primary-color;
        background-color: colors.$white;
        padding: 2.5rem 2rem;
        font-size: 1.2rem;

        &::before, &::after {
          content: none;
        }

        & h4, label {
          margin-top: 2.5rem;
          line-height: 1.4rem;

          &.message-type {
            margin-top: 0;
          }
        }

        & .send-message > .btn {
          float: right;
          margin: 1.7rem 0 0;
        }

        & .agreement-check {
          padding-top: 1.5rem;

          & checkbox label {
            font-size: 1.4rem;
          }
        }

        & .dropdown-menu {
          top: 3.9rem;
          min-width: 100%;
        }

        & .messenger-target {
          font-size: 1.4rem;
          font-weight: 300;
        }
      }

      & + li:not(.messenger-history-begin) {
        // white block to prevent line from growing too high
        &::after {
          display: inline-block;
          position: absolute;
          top: -0.2rem;
          background: colors.$white;
          width: 2rem;
          height: 3.3rem;
          content: "";
        }

        // white block placement if followed by right side box
        &.messenger-right {
          &::after {
            left: -1.1rem;
          }
        }

        // white block placement if followed by left side box
        &.messenger-left {
          &::after {
            right: -1.1rem;
          }
        }
      }

      // chat history is empty, make a cool gradient line for the big ball
      & + li.messenger-history-begin {
        &::before {
          display: inline-block;
          position: absolute;
          bottom: 5rem;
          left: 50%;
          background: linear-gradient(to bottom, transparent 0%, transparent 5%, $messenger-line-color 70%, $messenger-line-color 100%);
          width: spacing.$pixel-1;
          height: 15rem;
          content: "";
        }
      }
    }

    // rules for the history beginning
    &.messenger-history-begin {
      margin: 4.5rem 0 0;
      padding-bottom: 0;
      width: 100%;
      text-align: center;

      &::after {
        display: inline-block;
        position: absolute;
        top: -2.6rem;
        left: calc(50% - 1.4rem);
        border: spacing.$pixel-1 solid $messenger-ball-border;
        border-radius: 50%;
        background: colors.$white;
        width: 2.7rem;
        height: 2.7rem;
        content: "";
      }
    }
  }
}
