@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

@mixin tooltip-hierarchy-loop($counter) {
  @if $counter > 0 {
    > .tooltip-hierarchy:nth-child(#{$counter}) {
      padding-left: 1.5rem * $counter;
    }
  }
}

.course-unit-info-modal {
  & .course-unit-info-modal-header .info-box {
    & sis-menu-button {
      & .dropdown-toggle-button {
        position: relative;
        top: spacing.$pixel-1;
        margin-top: -0.4rem;
        border: 0;
        padding: 0.9rem 0.6rem;
        min-width: unset;
        color: colors.$middle-gray;

        &:hover, &:focus, &:active {
          color: colors.$dark-gray;
        }
      }
    }

    & .bottom-section {
      position: relative;

      & .nav-pills {
        padding-top: 2.5rem;
      }

      & .plan-location {
        max-width: 75%;
        white-space: nowrap;

        & sis-tooltip {
          display: block;
          margin-left: 3rem;

          & span.plan-location-name {
            display: block;
            position: relative;
            top: -0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        & sis-icon[icon="info-circle"] svg {
          float: left;
          margin-top: spacing.$spacing-xxs;

          @media (max-width: breakpoints.$screen-sm-max) {
            margin-top: -0.4rem;
          }
        }

        & a.tooltip-link {
          display: block;
          text-decoration: underline;
          text-decoration-style: dotted;
          line-height: 1.4rem;

          & .tooltip-hierarchy {
            @include tooltip-hierarchy-loop(30);

            margin-left: -1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;

            &:first-of-type::before {
              display: none;
            }

            & > .tooltip-hierarchy {
              padding-left: 0;

              &:nth-child(1) {
                margin-left: -0.2rem;

                &::before {
                  border-style: none;
                }
              }
            }
          }
        }

        @media (max-width: breakpoints.$screen-sm-max) {
          position: absolute;
          top: -0.2rem;
          left: 40%;
          max-width: 20rem;
          line-height: 1.2rem;

          & sis-tooltip span.plan-location-name {
            top: 0;
          }
        }

        @media (max-width: breakpoints.$screen-xs-max) {
          left: 0;
        }
      }
    }
  }

  & .modal-body {
    & .accordion-panel {
      & .content {
        & .courseunit-abbreviation {
          color: colors.$middle-gray;
        }

        & .content-text {
          & learning-environment-url {
            display: block;
          }
        }
      }
    }
  }
}
