@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

sis-plan-structure-composite-rule {
  .sis-plan-structure-composite-rule {
    &__body {
       .rule-group {
        border-left: spacing.$pixel-1 solid colors.$light-gray;

        &--active {
          border-left: spacing.$pixel-2 solid colors.$primary-color;
        }
      }
    }
  }
}
