@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-study-progress-graph-dropdown {
  & .study-status-header {
    display: block;
    text-align: left;
    font-size: spacing.$spacing-sm;

    &__title {
      color: colors.$dark-gray;

      @media (min-width: breakpoints.$screen-lg-min) {
        color: colors.$white;
      }

      @media (max-width: breakpoints.$screen-xs-max) {
        display: block !important;
      }
    }

    & .dropdown {
      display: inline-block;

      & .dropdown-toggle.text-dropdown {
        color: colors.$dark-gray;
        font-weight: 400;

        @media (min-width: breakpoints.$screen-lg-min) {
          color: colors.$white;

          & sis-icon[icon="chevron"] svg {
            fill: colors.$white;
          }
        }
      }

      @media (max-width: breakpoints.$screen-sm-max) {
        display: block;
      }
    }

    @media (min-width: breakpoints.$screen-lg-min) {
      max-width: 58rem;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      display: inline;
    }
  }
}
