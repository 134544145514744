@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@forward './studentBadge/studentBadge';
@forward './studyProgressGraph/studyProgressGraph';
@forward './timetable/timetableGrid/timetableGrid';
@forward './timetable/timetable';

.nav-div {
  display: block;
  position: relative;
  top: 0;
  z-index: 1010;
  width: 100%;
}

.frontpage {
  background-color: colors.$extra-light-gray;
  padding-bottom: 6rem;

  .alert-top-spacing {
    padding-top: 1.5rem;
  }

  & .student-frontpage-head {
    height: 25rem;

    &::before {
      display: block;
      position: absolute;
      left: 0;
      background-image: url('../../images/student-frontpage-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 25rem;
      content: "";
      filter: brightness(90%) contrast(110%);
    }

    @media (max-width: breakpoints.$screen-md-max) {
      height: 33rem;

      &::before {
        height: 33rem;
      }
    }
  }

  .frontpage-bottom {
    padding-top: 3rem;
    min-height: 40rem;

    & .message-and-calendar-wrapper {
      & timetable { /* stylelint-disable-line */
        @media (max-width: breakpoints.$screen-lg-max) {
          display: block;
          margin-top: 2rem;
        }
      }
    }

    & .notification-area {
      & .notification-type-selector {
        float: right;
        margin-top: spacing.$spacing-sm;
      }
    }
  }
}
