@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

timetable-grid {
  display: block;
  clear: both;
  margin: 0;
  border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;
  background-color: colors.$white;
  padding: 1em;
  max-height: 56rem;
  overflow: auto;

  .timetable-container {
    position: relative;
    width: inherit;

    .timetable-grid {
      display: block;
      position: relative;
      float: left;
      width: spacing.$spacing-xl;
      color: colors.$dark-gray;
      font-size: 1.2rem;
      font-weight: 400;

      span { // one hour row height
        // height: 100px; js hard coded value, see timetableConfigConstant
        display: block;
      }
    }
  }

  .timetable-lines {
    display: block;
    position: relative;
    top: 1rem;
    left: spacing.$spacing-xl;
    min-width: calc(100% - 4rem);

    > span {
      display: block;
      border-top: spacing.$pixel-1 solid colors.$light-gray;

      // height: 100px; js hard coded value, see timetableConfigConstant
    }
  }

  timetable-items {
    position: absolute;
    top: 0;
    left: 0;

    timetable-item {
      position: absolute;

      div.item-box {
        display: block;
        border-radius: spacing.$spacing-xxs;
        background-color: colors.$extra-light-gray;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .top-line {
          display: block;
          background-color: colors.$middle-gray;
          width: 100%;
          height: auto;
          color: colors.$white !important; // should be overridden by calendar event
          &.cancelled-time {
            text-decoration: line-through;
          }

          &.boulder-bg {
            color: colors.$white !important;
          }
        }

        .timetable-event {
          background-color: colors.$middle-gray;
        }

        .left-line {
          float: left;
          border: none;
          width: 0.5rem;
          height: 100%;
        }

        .content {
          display: block;
          background-color: colors.$extra-light-gray;
          padding: 0.5rem 0.5rem 0.5rem 1rem;
          width: auto;
          height: auto;
          line-height: 1.4rem;
          color: colors.$dark-gray;
          font-size: 1.2rem;
          font-weight: 400;

          & .name {
            display: block;
            font-weight: 300;
            overflow-wrap: break-word;
          }

          & .cancelled {
            display: block;
            text-transform: uppercase;
            color: colors.$red;
            font-weight: 600;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
      }

      // Calendar event item-element size and position
      // is calculated in javascript (items.directive.js & item.directive.js)
      // Margin and padding of the boxes can't be easily changed with CSS.
    }
  }
}
