@use '@funidata/ngx-fudis' as fudis;
@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/focus/mixins' as focus;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use 'sis-assets/foundations/typography/tokens' as typography;
@use '../mixins' as top-navigation;

$top-nav-height: 5.6rem;

@mixin nav-mobile-state($selector){
  display: block;

  .#{$selector}__nav-wrapper {
    position: absolute;
    z-index: 1000;
    border-bottom: spacing.$pixel-1 solid colors.$dark-gray;

    & .top-navigation {
      &__list {
        @include top-navigation.top-navigation-mobile-width;

        border: spacing.$pixel-1 solid colors.$dark-gray;
        border-top: 0;
        border-bottom: 0;
        background-color: colors.$white;

        &__item {
          display: flex;
          border-bottom: spacing.$pixel-1 solid colors.$light-gray;

          &:last-child {
            border-color: colors.$light-gray;
          }

          &__link {
            width: 100%;
            color: colors.$dark-gray;
            font-size: typography.$body-text-md-font-size;
            font-weight: typography.$font-weight-medium;

            &:hover {
              background-color: colors.$primary-color-light;
            }
          }
        }

        @media (max-width: breakpoints.$screen-md-max) {
          border-bottom-color: colors.$light-gray;
        }
      }
    }
  }
}

sis-top-navigation {
  z-index: 1010;

  & .top-navigation {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: colors.$dark-gray;
    min-height: $top-nav-height;

    &__divider {
      align-self: center;
      border-left: spacing.$pixel-1 solid colors.$white;
      height: 2.6rem;
    }

    &__link {
      padding: 0 spacing.$spacing-md;
      text-decoration: none;
      line-height: $top-nav-height;
      color: colors.$white;
      font-weight: typography.$font-weight-regular;

      &:hover {
        background-color: colors.$middle-gray;
        color: colors.$white;
      }

      &:focus {
        @include focus.fudis-focus-generic;
      }

      &--frontpage {
        &:hover {
          background-color: colors.$middle-gray;
        }

        &__logo {
          height: 100%;
        }
      }

      &--hidden {
        display: none;
      }

      &__menu-toggle, &__calc-icon {
        border: 0;
        background-color: transparent;
        cursor: pointer;
        padding: 0 spacing.$spacing-md;
        height: $top-nav-height;
      }

      &__calc-icon {
        align-items: center;
        padding-top: spacing.$pixel-2;
      }

      &--active,
      &--active:hover {
        background-color: colors.$white;
        cursor: default;
        color: colors.$dark-gray;
      }

      @media (max-width: breakpoints.$screen-lg-max) {
        font-size: typography.$body-text-sm-font-size;
      }
    }

    &__list {
      margin: 0;
      padding: 0;

      &__item {
        display: inline-flex;
        height: $top-nav-height;
        vertical-align: middle;
      }
    }

    &__left {
      display: flex;
      position: relative;
      align-items: flex-start;

      /* stylelint-disable-next-line selector-class-pattern */
      &:not(.top-navigation__left--overflow) .top-navigation__left__nav-wrapper__nav-el--closed {
        display: block;
      }

      &__nav-wrapper {
        &__user {
          @include fudis.body-text-md-regular;

          display: inline-flex;
          align-items: center;
          border-right: spacing.$pixel-1 solid colors.$dark-gray;
          border-left: spacing.$pixel-1 solid colors.$dark-gray;
          background-color: colors.$extra-light-gray;
          padding: 0 spacing.$spacing-md;
          width: 100%;
          height: 4rem;

          &-name,
          &-role {
            display: block;
            margin: spacing.$spacing-zero;
          }
        }

        &__nav-el{
          display: block;

          /* stylelint-disable-next-line no-descending-specificity */
          &--closed {
            display: none;
          }
        }
      }

      /* stylelint-disable-next-line selector-class-pattern */
      &--overflow:not(.top-navigation__left--calc) {
        @include nav-mobile-state("top-navigation__left");
      };

      &--calc {
        max-height: $top-nav-height;

        /* stylelint-disable-next-line no-descending-specificity, selector-class-pattern */
        & .top-navigation__left__nav-wrapper__nav-el--closed {
          display: block;
          opacity: 0;
        }
      }

      // Original mobile style trigger point
      // @media (max-width: breakpoints.$screen-lg-max) {
      //   @include nav-mobile-state("top-navigation__left");
      // }
    }

    &__right {
      display: flex;
      align-items: center;
    }

    @media (max-width: breakpoints.$screen-xs-max) {
      flex-flow: wrap;
    }
  }
}




