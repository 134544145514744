@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use '../../mixins' as top-navigation;

sis-top-navigation-icons {
  .top-navigation-icons {
    display: flex;

    &__list {
      display: inherit;
      margin: 0;
      padding: 0;
      list-style: none;

     &__item {
       display: inherit;
     }
    }

    @media (max-width: breakpoints.$screen-md-max) {
      &__list {
        @include top-navigation.top-navigation-mobile-width;

        display: block;
        border: spacing.$pixel-1 solid colors.$dark-gray;
        border-top: 0;
        border-bottom-color: colors.$light-gray;
        background-color: colors.$extra-light-gray;
      }
    }
  }
}
