@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

student-badge {
  & .name-holder {
    & .name {
      display: inline-block;
      margin-top: 11rem;
      margin-left: 3rem;
      vertical-align: top;
      line-height: spacing.$spacing-lg;
      color: colors.$extra-light-gray;
      font-size: 2.8rem;
      font-weight: 300;

      @media (max-width: breakpoints.$screen-sm-max) {
        margin-top: spacing.$spacing-xs;
        margin-left: 0;
        font-size: 1.8rem;
      }

      @media (min-width: breakpoints.$screen-md-min) and (max-width: breakpoints.$screen-md-max) {
        position: absolute;
        top: calc(42% + #{spacing.$pixel-1});
        left: 0;
        transform: perspective(#{spacing.$pixel-1}) translateY(-50%);
        margin: 0;
        width: 100%;
        max-height: 7.2rem;
        overflow: hidden;
        text-align: center;
        line-height: spacing.$spacing-md;
        font-size: 2rem;
      }
    }

    @media (min-width: breakpoints.$screen-md-min) and (max-width: breakpoints.$screen-md-max) {
      position: relative;
      height: 6.6rem;
    }
  }

  & .image-holder {
    & .image {
      display: inline-block;
      margin-top: 5rem;
      margin-left: 3rem;
      border: spacing.$pixel-2 solid colors.$light-gray;
      border-radius: 50%;
      background-color: colors.$extra-light-gray;
      width: 15rem;
      height: 15rem;
      overflow: hidden;

      & span.image-placeholder {
        display: inline-block;
        transform: translate(-50%, -50%);
        margin-top: 50%;
        margin-left: 50%;
        color: colors.$middle-gray;
        font-size: 7.2rem;
        font-weight: 600;

        @media (max-width: breakpoints.$screen-sm-max) {
          font-size: 2.2rem;
        }
      }

      @media (max-width: breakpoints.$screen-sm-max) {
        margin-top: spacing.$spacing-xs;
        margin-left: 0;
        width: 3rem;
        height: 3rem;

        &::after {
          margin-top: 0;
        }
      }

      @media (min-width: breakpoints.$screen-sm-min) and (max-width: breakpoints.$screen-sm-max) {
        margin-top: 1rem;
        margin-left: 6rem;
      }

      @media (min-width: breakpoints.$screen-md-min) and (max-width: breakpoints.$screen-md-max) {
        margin-top: 1.7rem;
        margin-left: 7.2rem;
      }
    }
  }

  .slogan {
    font-style: italic;
  }

  & .student-badge {
    @media (max-width: breakpoints.$screen-xs-max) {
      margin: 0 -1.5rem;
      background: rgb(82 112 149 / 20%);
      padding: 0 1.5rem;
    }
  }
}
