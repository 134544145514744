@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

study-progress-graph {
  & .study-status {
    display: block;
    position: absolute;
    top: 12rem;
    right: 1.5rem;
    min-height: 22.5rem;
    text-align: right;
    color: colors.$white;

    & > span {
      color: colors.$dark-gray;
      font-weight: 400;
    }

    & .study-graph--overflow {
      @media (max-width: breakpoints.$screen-sm-max) {
        overflow-x: scroll;
      }

    }

    & .study-graph {
      display: inline-block;
      position: relative;
      margin: 0 spacing.$pixel-3 1rem spacing.$pixel-2;
      background-color: rgb(0 0 0 / 50%);
      width: 18rem;
      height: 18rem;
      vertical-align: top;

      // first graph box rounded corners
      &:first-of-type {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      // last graph box rounded corners
      &:last-of-type {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      & .study-sub-canvas {
        position: relative;
        top: 1rem;
        left: 0.5rem;
      }

      &.average-box {
        padding: 1rem 0 0 1.5rem;
        width: 23rem;

        & .average-box-headers {
          display: block;
          text-align: left;
          text-transform: none;
          font-size: 1.3rem;

          & sis-popover .sis-popover-trigger-content {
            display: block;
            color: colors.$white;
            font-weight: 400;
          }
        }

        & .average-box-number {
          display: block;
          padding: 1.2rem 0 1.4rem;
          text-align: left;
          font-size: 5.5rem;
          font-weight: 300;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        @media (max-width: breakpoints.$screen-sm-max) {
          display: block;
          padding-left: spacing.$spacing-xs;
          width: 37rem;
          height: spacing.$spacing-xl;
          text-align: center;

          & .average-box-headers {
            display: inline-block;
            position: relative;
            top: -1.5rem;
            font-size: 1.2rem;
          }

          & .average-box-number {
            display: inline-block;
            position: relative;
            top: -1.4rem;
            font-size: 1.4rem;
            font-weight: 600;

            &:nth-child(2) {
              margin-right: spacing.$spacing-xs;
            }
          }
        }
      }

      & .studies-box-headers, .studies-header-numbers, .studies-small-numbers, .studies-addiotional-charts {
        display: block;
        position: absolute;
        bottom: 11.3rem;
        width: 100%;
        height: 2rem;
        text-align: center;
        text-transform: none;
        font-size: 1.3rem;
      }

      & .studies-header-numbers {
        bottom: 5rem;
        height: spacing.$spacing-xl;
        font-size: 5.5rem;
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      & .studies-small-numbers {
        bottom: 3rem;
        height: 2rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      & .studies-additional-charts {
        position: absolute;
        right: 7.5rem;
        bottom: 1rem;
        height: 2rem;

        & ngb-popover-window {
          width: 50.7rem;

          &.bottom {
            min-height: 0;

            &.bottom-right {
              margin-left: 0.9em;
            }
          }

          @media (max-width: breakpoints.$screen-sm-max) {
            width: 18.3rem;

          }
        }

        & span.popover-trigger {
          width: 1.3rem;
        }

        /* stylelint-disable no-descending-specificity */
        & .sis-popover-trigger-content {
          text-align: right;
          color: colors.$white;
        }
        /* stylelint-enable no-descending-specificity */

        & .bs-popover-bottom-right {
          margin-left: spacing.$spacing-xxs;
        }

        & .study-status-sub-graph {
          & .study-sub-graph {
            display: inline-block;
            position: relative;
            margin: spacing.$spacing-xs 1.3rem 0 1.3rem;
            width: 12.5rem;
            overflow: hidden;
            text-align: center;

            & .study-sub-graph-header {
              display: block;
              width: 12.5rem;
              overflow: hidden;
              text-transform: none;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: colors.$dark-gray;
            }

            & .study-sub-graph-numbers {
              position: absolute;
              bottom: 5.6rem;
              left: 0;
              width: 12.5rem;
              letter-spacing: -0.3rem;
              color: colors.$primary-color;
              font-size: 3.7rem;
              font-weight: 300;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            & .study-sub-graph-small-numbers {
              display: block;
              position: relative;
              bottom: 3.4rem;
              text-align: center;
              color: colors.$primary-color;
              font-size: 1.4rem;
              font-weight: 300;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
        }
      }

      // if first graph box is not visible, round the 2nd graph box
      @media (max-width: breakpoints.$screen-lg-max) {
        &:nth-of-type(2) {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
      }
    }

    @media (max-width: breakpoints.$screen-sm-max) {
      top: 12.8rem;
      right: 0;
      width: 37rem;
      max-width: 100vw;
      overflow: visible;
      white-space: nowrap;
    }

    @media (min-width: breakpoints.$screen-md-min) and (max-width: breakpoints.$screen-md-max) {
      top: 15.2rem;
      right: unset;
      left: 6rem;
    }
  }
}
