@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

app-search-main, app-search-cooperation-network, app-search-open-university {
  & .search-page {
    & label {
      text-transform: none !important;
    }

    & .search-bar-content {
      padding-left: 0;
    }

    & .search-filter {
      border-bottom: spacing.$pixel-1 solid colors.$dark-gray;
      padding-bottom: spacing.$spacing-sm;

      & .search-filter-more-button > button {
        margin-top: spacing.$spacing-xxs;
      }

      & .search-filter-item {
        margin-bottom: spacing.$spacing-xs;
      }
    }

    & .search-filter-clear-button {
      position: relative;
      height: 6.9rem;

      & > sis-button {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    & .form-group {
      margin-bottom: spacing.$spacing-xs;
    }

    & .search-main-input-group {
      & input {
        border-right: none;
      }

      & sis-button {
        & .btn {
          margin-right: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    & sis-university-select-editor {
      display: block;
      left: 0;
    }

    & .below-searchbar {
      & .results-info {
        margin-top: spacing.$spacing-sm;
        margin-bottom: spacing.$spacing-xs;

        & .choose-sort-type {
          margin-bottom: 0;
        }
      }

      & .query-notifications {
        & .results-no-show {
          margin-top: spacing.$spacing-xs;
        }
      }
    }

    & .search-result-pagination {
      padding-top: spacing.$spacing-md
    }
  }
}
