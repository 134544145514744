@use 'sis-assets/fonts/icon-fonts/icons' as font;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;
@use '@funidata/ngx-fudis' as fudis;

// Plain div that shows checkbox status. No input element included.
div.checkbox-button {
  border: spacing.$pixel-1 solid colors.$silver;
  border-radius: 0;
  background: colors.$white;
  cursor: pointer;
  padding-top: spacing.$pixel-1;
  padding-left: spacing.$pixel-2;
  width: 2rem;
  height: 2rem;
  text-transform: lowercase;
  color: colors.$middle-gray;
  font-family: SISUicons !important;
  font-size: spacing.$spacing-sm;

  &.checked::before {
    position: absolute;
    line-height: 1.7rem;
    font-size: spacing.$spacing-md;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    content: 'x';

    @supports (content: 'x' / 'y') {
      content: 'x' / 'selected';
    }
  }

  &.disabled {
    background: colors.$extra-light-gray;
    cursor: default;
    color: colors.$silver;
  }
}

div.checkbox:not(.checkbox-toggle) {
  & label {
    padding-left: 3.3rem;
    text-transform: none;
    line-height: 3rem;
    font-size: spacing.$spacing-sm;

    & input[type='checkbox'] {
      margin-left: -3.2rem;
      border: spacing.$pixel-1 solid colors.$silver !important;
      border-radius: 0 !important;
      background-image: none;
      padding-top: spacing.$pixel-1;
      padding-left: spacing.$pixel-2;
      width: 2rem;
      height: 2rem;
      color: colors.$middle-gray;
      font-family: SISUicons !important;

      &:checked {
        @include font.SISUicon-Check;
      }

      &:disabled, &.disabled {
        background: colors.$extra-light-gray;
        cursor: default;
        color: colors.$silver;
      }
    }
  }
}

label.control,
label.sis-checkbox.control,
.control {
  display: inline-flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding-left: spacing.$spacing-lg;
  width: auto;
  min-height: spacing.$spacing-lg;
  text-transform: initial !important;
  font-size: spacing.$spacing-sm;

  &:hover {
    &:checked ~ .control-indicator, &:checked ~ &__indicator {
      background: colors.$white;
    }

    & ~ .control-indicator, & ~ &__indicator {
      background: colors.$white;
    }
  }

  &.control-inline, &--inline {
    display: inline-block;
    margin-right: spacing.$spacing-lg;
    padding-top: spacing.$pixel-2;

    &.control-checkbox, &.control--checkbox {
      & .control-indicator, & .control__indicator {
        top: spacing.$pixel-3;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  & input[type="checkbox"], & input[type="radio"] {
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: -1;

    &:focus ~ .control-indicator, &:focus ~ .control__indicator {
      @include fudis.focus-form-field;

      background: colors.$white;
    }

    &:checked {
      &:focus ~ .control-indicator, &:focus ~ .control__indicator {
        @include fudis.focus-form-field;

        background: colors.$white;

      }

      & ~ .control-indicator, & ~ .control__indicator {
        background: colors.$white;

        &::after {
          display: block;
        }
      }
    }

    &:disabled ~ .control-indicator, &:disabled ~ .control__indicator {
      opacity: 0.6;
      background: colors.$extra-light-gray;
      cursor: not-allowed;
    }
  }

  & .control-indicator, &__indicator {
    position: absolute;
    top: 0.6rem; //aligns checkbox to label center in one row, aligns top when multiple label rows
    left: 0;
    border: spacing.$pixel-1 solid colors.$middle-gray;
    background: colors.$white;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    font-family: SISUicons !important;

    &::after {
      display: none;
      position: absolute;
      content: '';
    }

    &:active {
      border-color: colors.$primary-color;
    }

    &:focus {
      @include fudis.focus-form-field;
    }

    &.invalid {
      border-color: colors.$red;
    }
  }

  &.control-checkbox, &--checkbox {
    &.single-checkbox {
      margin-bottom: 0;
    }

    & .control-indicator, & .control__indicator {
      text-align: start;
      font-family: SISUicons !important;

      &::after {
        width: 1.8rem;
        height: 1.8rem;
        color: colors.$middle-gray;
      }
    }

    & input {
      &:checked {
        & ~ .control-indicator, & ~ .control__indicator {
          line-height: 1.8rem;

          &::after {
            display: block;
            margin-left: spacing.$pixel-2;
            font-size: spacing.$spacing-md;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: 'x';

            @supports (content: 'x' / 'y') {
              content: 'x' / 'selected';
            }
          }
        }
      }

      &:disabled ~ .control-indicator::after, &:disabled ~ .control__indicator::after {
        border-color: colors.$middle-gray;
      }
    }
  }
}

.form-group.checkbox-group ul.error-list li {
  margin-top: 0;
}

.form-group.checkbox-group p.help-block {
  margin-top: 0;
}

.form-group.checkbox-group label:not(.control) {
  display: block;
}

sis-checkbox,
sis-form-checkbox {
  display: block;
  line-height: 1;
  color: colors.$dark-gray;
  font-size: spacing.$spacing-sm;
  font-weight: 400;

  .control-checkbox {
    display: inline-flex;
    position: relative;
    align-items: center;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: inherit;

    .control-indicator {
      display: inline-block;
      position: static;

      th.checkbox-cell & {
        box-shadow: 0 0 spacing.$pixel-2 spacing.$pixel-3 colors.$extra-light-gray;
      }
    }

    .secondary-label {
      margin-left: 0.5rem;
      padding-bottom: spacing.$pixel-3;
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
}

checkbox {
  display: block;
}
