suggest-add-message-modal {
  & .modal-body {
    & ul.wizard-progress {
      margin-bottom: 0;
      padding-left: 0;
    }

    .step2 {
      .form-group label {
        display: inline-block;
      }
    }

    .step3 {
      .studymodule,
      .courseunit {
        margin-bottom: 1rem;
      }
    }

    & em {
      font-weight: 400;
    }
  }
}
