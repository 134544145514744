@use 'sis-assets/foundations/breakpoints/breakpoints' as breakpoints;
@use 'sis-assets/foundations/colors/colors' as colors;
@use 'sis-assets/foundations/spacing/tokens' as spacing;

/* stylelint-disable-next-line selector-type-no-unknown */
timetable {
  & .timetable {
    position: relative;

    & dropdown-select {
      position: absolute;
      top: -0.4rem;
      right: 0;
      border-left: spacing.$spacing-xs solid colors.$primary-color;
      border-radius: spacing.$spacing-xxs spacing.$spacing-xxs 0 0;
      background: colors.$white;
      padding: 0 spacing.$spacing-sm;

      & .dropdown-toggle {
        margin: 1.4rem 0;
        line-height: spacing.$spacing-md;
        font-size: 1.8rem;
        font-weight: 400;
      }

      @media (max-width: breakpoints.$screen-md-max) {
        top: -1.6rem;
      }
    }

    & .timetable-no-events {
      clear: both;
      border-top-left-radius: spacing.$spacing-xxs;
      background: colors.$white;
      padding: 1rem;
    }
  }
}
